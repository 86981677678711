import Home from "../home";
import ActivityLogs from "../activity_log";
import AddApplication from "../Application_Configuration";
import AssignRole from "../assignrole";
import BannerImage from "../banner_image";
import CoCThirdPartyWidgetDemo from "../CoC_ThirdPartyWidget.tsx";
import ContactABB from "../contact_abb";
import Extensions from "../extensions";
import ImportExportApplication from "../ImportExportApplication";
import InvalidPage from "../invalid";
import MyApps from "../myapps";
import MyLearning from "../mylearning";
import MyQcs from "../MyQCS";
import PlatformAdministrator from "../platform_administrator";
import ProfileHistorian from "../ProfileHistorian";
import RoleDefinition from "../role_definition";
import ServicePro from "../service_pro";
import Services from "../services";
import SessionControl from "../sessioncontrol";
import UserMappingAndRole from "../usermappingandrole";
import WinderPerformance from "../winderperformance";
import InviteNewUser from "../inviteNewUser";
import NewUserRequest from "../newUserRequest";
import AssignUserRole from "../assignUserRole";
import MyCaseEntry from "../CaseCreationForm";
import ServiceProAccessRequest from "../serviceProAccessRequest";
import RolesAndUserManagement from "../RolesAndUserManagement";
import ApplicationProvisionLanding from "../ApplicationProvisionLanding";

export const routeConfig = [
  {
    path: "/intro",
    element: <Home />,
  },
  {
    path: "/my_apps",
    element: <MyApps />,
  },
  {
    path: "/service_apps",
    element: <Services />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/extensions",
    element: <Extensions />,
  },
  {
    path: "../CaseCreationForm.html",
    element: <Extensions />,
  },
  {
    path: "/platform_administration",
    element: <PlatformAdministrator />,
  },
  {
    path: "/platform_administration/manage_applications",
    element: <ApplicationProvisionLanding />,
  },
  {
    path: "/platform_administration/manage_applications/Application_Provisioning",
    element: <AddApplication />,
  },
  {
    path: "/platform_administration/activity_logs",
    element: <ActivityLogs />,
  },
  {
    path: "/platform_administration/application_provision",
    element: <AddApplication />,
  },
  {
    path: "/platform_administration/session_control",
    element: <SessionControl />,
  },
  {
    path: "/platform_administration/roles_user_management",
    element: <RolesAndUserManagement />,
  },
  {
    path: "/platform_administration/roles_user_management/role_definition",
    element: <RoleDefinition />,
  },
  // {
  //   path: "/platform_administration/roles_user_management/role_definition/new_role",
  //   element: <NewRole />,
  // },
  {
    path: "/platform_administration/roles_user_management/user_mapping",
    element: <UserMappingAndRole />,
  },
  {
    path: "/platform_administration/roles_user_management/user_mapping/assign_role",
    element: <AssignRole />,
  },
  {
    path: "/platform_administration/roles_user_management/user_mapping/assignUserRole",
    element: <AssignUserRole />,
  },
  {
    path: "/platform_administration/roles_user_management/user_mapping/invite_new_user",
    element: <InviteNewUser />,
  },
  {
    path: "/extended_solutions/winder_performance",
    element: <WinderPerformance />,
  },
  {
    path: "/my_learning",
    element: <MyLearning />,
  },
  {
    path: "/banner_image",
    element: <BannerImage />,
  },
  {
    path: "/platform_administration/manage_applications/Import_Export_Application",
    element: <ImportExportApplication />,
  },
  {
    path: "/contact_abb",
    element: <ContactABB />,
  },
  {
    path: "/my_qcs",
    element: <MyQcs />,
  },
  {
    path: "/profile_Historian",
    element: <ProfileHistorian />,
  },
  {
    path: "/coc_third_party_widget_demo",
    element: <CoCThirdPartyWidgetDemo />,
  },
  {
    path: "/servicepro",
    element: <ServicePro />,
  },
  {
    path: "/serviceproAccessRequest",
    element: <ServiceProAccessRequest />,
  },
  {
    path: "/platform_administration/roles_user_management/user_requests",
    element: <NewUserRequest />,
  },
  {
    path: "*",
    element: <InvalidPage />,
  },
  {
    path: "/contact_abb/casecreationform",
    element: <MyCaseEntry />,
  },
];
