import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import HMIComponent from "./HMIComponent";
import { useUserContext } from "./app_context/UserContext";
import { addRefreshToken } from "./utils/apiServices";
import { useNotification } from "./app_context/NotificationContext";
import { routeConfig } from "./utils/routes";
import Cookies from "js-cookie";
import { HMIContextProvider } from "./app_context/HMIContext";
import { getUserPreferenceService } from "./services/myPnpServices";
import Modal from "react-modal";
import { MUIButton } from "@abb/abb-arcadia-ui-react";

Modal.setAppElement("#root"); // For accessibility

export default function Application() {
  const { userData } = useUserContext();
  const { showNotification } = useNotification();
  const [getAllUserPreference, setGetAllUserPreference] = useState<any>([]);
  const [defaultAppURL, setDefaultAppURL] = useState<string>("");
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false); // State for dialog
  const location = useLocation();
  //const { theme, setTheme } = useTheme();
  const [theme, setTheme] = useState("light");

  const token: any = Cookies.get("access_token");

  // useEffect(() => {
  //   loadTheme(theme);
  // }, [theme]);

  const loadTheme = (theme: string) => {
    document.documentElement.className = ""; // Clear any existing class
    document.documentElement.classList.add(
      theme === "dark" ? "dark-theme" : "light-theme"
    );
    localStorage.setItem("userTheme", theme);
  };

  const getUserPreference = async (access_token: string) => {
    try {
      const response = await getUserPreferenceService();

      if (response.status === 401) {
        console.error("Unauthorized access - possibly invalid token");
        return;
      }
      setGetAllUserPreference(response);

      if (response.length > 0) {
        const url = response[0].appURL;
        setDefaultAppURL(url);
        if (location.pathname === "/home" && url !== "/home") {
          setIsDialogOpen(true);
        }
        const userTheme = response.data[0].theme;
        setTheme(userTheme);
        localStorage.setItem("userTheme", userTheme);
      }
    } catch (error) {
      console.error("Failed to fetch user preferences:", error);
    }
  };

  const handleConfirm = () => {
    setIsRedirecting(true);
    if (defaultAppURL.includes("https")) {
      window.location.href = `${defaultAppURL}`;
    } else {
      const base_url = window.location.href;
      const lastSlashIndex = base_url.lastIndexOf("/");
      const newURL = `${base_url.substring(0, lastSlashIndex)}${defaultAppURL}`;
      window.location.href = newURL;
    }
  };

  useEffect(() => {
    addRefreshToken(async () => {
      await userData.callRefreshToken();
    });
  }, [userData]);

  useEffect(() => {
    if (!token && userData?.callRefreshToken) userData?.callRefreshToken();
  }, [token, userData]);

  if (isRedirecting) {
    return (
      <div>
        <p>Redirecting to the default screen...</p>
      </div>
    );
  }

  return (
    <HMIContextProvider defaultAppURL={defaultAppURL}>
      <HMIComponent>
        {token && (
          <Routes>
            {routeConfig.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
                //element={React.cloneElement(route.element, { theme })} // Pass theme prop
              />
            ))}
            <Route path="/user_preferences" element={null} />
          </Routes>
        )}
      </HMIComponent>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onRequestClose={() => setIsDialogOpen(false)}
        onConfirm={handleConfirm}
        message="Do you want to continue to the default application?"
      />
    </HMIContextProvider>
  );
}

// Internal Modal Component
function ConfirmationDialog({
  isOpen,
  onRequestClose,
  onConfirm,
  message,
}: any) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Confirmation Dialog"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "400px",
          height: "180px",
          padding: "20px",
          borderRadius: "2px",
          borderTop: "7px solid #0051FF",
        },
      }}
    >
      <div className="modal-popup-box">
        <h2>Confirmation</h2>
        <p>{message}</p>
      </div>

      <div className="confirm-buttons">
        <MUIButton
          label="Cancel"
          onClickButton={onRequestClose}
          size={32}
          variant="secondary"
        />
        <MUIButton
          label="Yes"
          onClickButton={onConfirm}
          size={32}
          variant="primary"
        />
      </div>
    </Modal>
  );
}
